/* You can add global styles to this file, and also import other style files */
@import './assets/fonts/style-font.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #010216;
}

@media only screen and (max-width: 1536px) {
  body {
    zoom: 0.85;
    -ms-zoom: 0.85;
    -webkit-zoom: 0.85;
  }

  app-wave-form-audio {
    zoom: 1.18 !important;
  }

  app-go-inlive,
  app-go-inlive-viewer {
    zoom: 1.15;
  }

  app-side-right,
  app-side-left {
    -moz-transform: scale(0.9, 0.9);
    -moz-transform-origin: top center;
  }

  app-home {
    .home-page {
      -moz-transform: scale(0.9, 0.9);
      -moz-transform-origin: top center;
    }
  }
}

@media only screen and (max-width: 1440px) {
  body {
    zoom: 0.8;
    -ms-zoom: 0.8;
    -webkit-zoom: 0.8;
  }

  app-wave-form-audio {
    zoom: 1.25 !important;
  }

  app-go-inlive,
  app-go-inlive-viewer {
    zoom: 1.2;
  }

  app-side-right,
  app-side-left {
    -moz-transform: scale(0.8, 0.8);
    -moz-transform-origin: top center;
  }

  app-home {
    .home-page {
      -moz-transform: scale(0.8, 0.8);
      -moz-transform-origin: top center;
    }
  }
}

@media only screen and (max-width: 1280px) {
  body {
    zoom: 0.75;
    -ms-zoom: 0.75;
    -webkit-zoom: 0.75;
  }

  app-wave-form-audio {
    zoom: 1.33 !important;
  }


  app-go-inlive,
  app-go-inlive-viewer {
    zoom: 1.25;
  }

  app-side-right,
  app-side-left {
    -moz-transform: scale(0.75, 0.75);
    -moz-transform-origin: top center;
  }

  app-home {
    .home-page {
      -moz-transform: scale(0.75, 0.75);
      -moz-transform-origin: top center;
    }
  }
}

@media only screen and (max-width: 1080px) {
  body {
    zoom: 0.7;
    -ms-zoom: 0.7;
    -webkit-zoom: 0.7;
  }

  app-wave-form-audio {
    zoom: 1.18 !important;
  }


  app-go-inlive,
  app-go-inlive-viewer {
    zoom: 1.3;
  }

  app-side-right,
  app-side-left {
    -moz-transform: scale(0.7, 0.7);
    -moz-transform-origin: top center;
  }

  app-home {
    .home-page {
      -moz-transform: scale(0.7, 0.7);
      -moz-transform-origin: top center;
    }
  }
}

@media only screen and (max-width: 900px) {
  body {
    zoom: 1;
    -ms-zoom: 1;
    -webkit-zoom: 1;
  }
}

* {
  font-family: 'DMSans-regular';

  .p-dropdown {
    border-radius: 4rem !important;
  }

  .dropdown-custom {
    .p-multiselect {
      border: none !important;
      background: transparent !important;
    }

    .p-dropdown {
      height: 32px;
      background: transparent !important;
      height: 32px;
      display: flex;
      align-items: center;
    }
  }

  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    display: none !important;
  }

  .text-white {
    color: white !important;
  }

  .text-gray {
    color: #707070 !important;
  }

  .text-black {
    color: black !important;
  }

  .text-bold {
    //font-weight: bold;
    font-weight: bold;
  }

  .text-regular {
    font-family: "DMSans-regular";
  }

  .w-100 {
    width: 100%;
  }

  .w-90 {
    width: 90%;
  }

  .w-80 {
    width: 80%;
  }

  .w-70 {
    width: 70%;
  }

  .w-50 {
    width: 50%;
  }

  .w-60 {
    width: 60%;
  }

  .w-40 {
    width: 40%;
  }

  .w-30 {
    width: 30%;
  }

  .w-20 {
    width: 20%;
  }

  .h-100 {
    height: 100%;
  }

  .h-90 {
    height: 90%;
  }

  .h-80 {
    height: 80%;
  }

  .h-60 {
    height: 60%;
  }

  .h-40 {
    height: 40%;
  }

  .h-20 {
    height: 20%;
  }

  input {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .p-dialog .p-dialog-content {
    background-color: transparent !important;
    border-bottom-right-radius: 23px !important;
    border-bottom-left-radius: 23px !important;
  }

  .p-dialog .p-dialog-header {
    background-color: transparent !important;
    border-top-right-radius: 23px !important;
    border-top-left-radius: 23px !important;
  }

  .p-dialog {
    border-radius: 23px !important;
    background: linear-gradient(#000344 0%, #010216 100%);
    z-index: 10000000;
  }

  .text-error {
    color: red;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    // border: 1px solid green;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px #0000 inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #000000 !important;
  color: #ffffff !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box .p-focus {
  border-color: #000000;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da !important;
  background: #00000000 !important;
  width: 22px;
  height: 22px;
  color: #ffffff !important;
  border-radius: 8px !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff !important;
  font-size: 12px !important;
}

.p-checkbox .p-checkbox-box .p-highlight {
  border-color: white !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #0186fa !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #0186fa !important;
  background: #0186fa !important;
}

input[type='checkbox' i] {
  width: 20px !important;
  height: 20px !important;
  accent-color: #242424;
  border: solid 1px !important;
}

input[type='checkbox' i]:hover {
  accent-color: #242424;
}

.p-menu {
  z-index: 1001;
  transform-origin: center top;
  width: 17rem !important;
}

.p-menu.p-menu-overlay {
  background: linear-gradient(#000344 0%, #010216 100%) !important;
  width: fit-content !important;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  // color: #8d8d8d !important;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 3rem !important;
  height: 3rem !important;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev {
  left: 2rem;
  z-index: 1;
}

.p-carousel .p-carousel-content .p-carousel-next {
  right: 2rem;
  z-index: 1;
}

.p-carousel-indicators {
  display: none !important;
}

.line {
  border-bottom: 1px solid #7070709c;
}

.text-noti-red {
  color: #ff5858;
}

.text-disabled {
  color: #a1a1a1 !important;
}

.text-title {
  font-size: 26px;
  font-weight: bold;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-500 {
  font-weight: 500;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.overflow-wrap-breakword {
  overflow-wrap: break-word;
}

app-loading {
  z-index: 9999999999;

  .p-dialog {
    box-shadow: none !important;
    background: transparent !important;
  }

  .p-dialog-header,
  .p-dialog-content {
    background: transparent !important;
  }

  .p-dialog .p-dialog-content {
    background: transparent !important;
  }

  .p-dialog .p-dialog-header {
    background: transparent !important;
  }
}

app-nav-menu {
  .p-dropdown {
    background: none;
    border-radius: 5rem;
    height: 32px;
    display: flex;
    align-items: center;
    // width: 80px;
  }

  .p-dropdown-label {
    text-overflow: clip;
  }

  .p-dropdown-panel {
    left: 0 !important;
  }

  .p-menu .p-menuitem-link,
  .p-menu .p-menuitem-link {

    .p-menuitem-icon,
    .p-menuitem-text {
      color: white !important;
    }


    &:hover {

      .p-menuitem-icon,
      .p-menuitem-text {
        color: black !important;
      }
    }
  }
}

app-login {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }
}

app-reset-password {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }
}

app-register {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }

  #birthday.p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 20px !important;
    // border: 1px solid #707070 !important;
    border-width: 1px !important;
    color: white;
    width: 80%;
  }

  .p-calendar {
    .p-inputtext {
      font-size: 18px;
      background: transparent !important;
      border-radius: 10px !important;
      border: 1px solid #707070 !important;
      // border-width: 0px 0px 2px 0px !important;
      color: white;
    }
  }

  .p-checkbox .p-checkbox-box {
    border-radius: 6px !important;
  }
}

app-complete-account {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }

  app-step-two {
    .p-inputtext {
      font-size: 18px;
      border-radius: 12px !important;
      border: none !important;
      border-width: 0px 0px 2px 0px !important;
      color: white;
      background: #8c8c8c5c !important;
    }
  }

  .p-galleria .p-galleria-close .p-galleria-close-icon {
    display: none !important;
  }
}

app-explorer {
  .p-togglebutton.p-button {
    border-radius: 10rem !important;
  }

  .list-goinlive {

    .p-carousel .p-carousel-content .p-carousel-prev,
    .p-carousel-next {
      margin-top: -37px !important;
    }
  }
}

app-detail-club {
  .p-togglebutton.p-button {
    border-radius: 10px !important;
    font-size: 16px;
  }

  span.p-button-label {
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    //color: white;
  }

  span.p-dialog-header-close-icon {
    color: white;
  }

  .scenes {
    button {
      .p-button-label {
        font-weight: 500 !important;
      }
    }
  }
}

app-step-ii,
app-step-i,
app-step-i-create-stage,
app-step-iv {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white !important;
  }

  .p-dropdown {
    width: 100%;
    background: transparent;
    border-radius: 10px !important;
  }

  .p-dropdown .p-dropdown-label {
    background: transparent !important;
    border: 0 none !important;
  }
}

app-edit-user {
  input.p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: #707070 !important;
  }

  .p-calendar {
    .p-inputtext {
      font-size: 18px;
      background: transparent !important;
      border-radius: 10px !important;
      border: 1px solid #707070 !important;
      // border-width: 0px 0px 2px 0px !important;
      color: white;
    }
  }

  .p-checkbox .p-checkbox-box {
    border-radius: 6px !important;
  }

  .p-dialog {
    width: 40%;
  }

  .p-dialog .p-dialog-content {
    color: white !important;
    text-align: center;
  }

  p-dropdown {
    width: 100%;

    .p-dropdown {
      background: transparent !important;
      border: 1px solid #ced4da;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
      border-radius: 10px !important;
      width: 100%;
    }

    .p-inputtext {
      font-size: 18px;
      color: white;
    }
  }
}

app-side-left {
  .p-skeleton {
    background-color: #b0b0b0 !important;
    border-radius: 6px;
  }
}

app-create-album {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    // border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }

  .p-dropdown {
    background: transparent !important;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 10px !important;
  }
}

app-create-album-step-i {
  .p-radiobutton .p-radiobutton-box {
    background: transparent !important;
  }

  span.p-dropdown-label {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dropdown-panel {
    width: 10rem;
  }
}

app-create-album-step-iii {
  .p-radiobutton .p-radiobutton-box {
    background: transparent !important;
  }

  span.p-dropdown-label {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dropdown-panel {
    width: 10rem;
  }
}

app-step-i-create-stage {
  .p-radiobutton .p-radiobutton-box {
    background: transparent !important;
  }
}

app-view-media-album {
  .p-dialog {
    height: 100%;
    width: 100%;
    max-height: 100%;
  }

  .p-galleria-mask {
    background-color: black !important;
  }

  .p-carousel {
    height: 100%;

    img {
      position: absolute;
      max-height: 100% !important;
      max-width: 100% !important;
    }

    video {
      position: absolute;
      max-height: 100% !important;
      max-width: 100% !important;
    }
  }

  .p-carousel-indicators {
    display: flex !important;
  }

  .p-dialog-content {
    overflow-y: inherit !important;
  }

  .p-carousel-items-content {
    height: 100% !important;
  }

  .p-carousel-content {
    height: 100% !important;
  }

  .p-carousel-items-container {

    height: 100% !important;
  }

  .p-carousel-container {
    height: 100% !important;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
}

app-view-media-post {
  .p-carousel-indicators {
    display: flex !important;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }

  .p-dialog {
    max-height: 100% !important;
  }

  .p-dialog .p-dialog-header {
    padding: 0px !important;
  }

  emoji-mart {
    position: absolute;
    right: 14rem;
    bottom: 75px;
    z-index: 1;
  }
}

app-detail-club {
  .p-togglebutton.p-button {
    padding: 20px 40px;
  }
}

app-edit-club {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    // border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }


  p-dropdown {
    .p-inputtext {
      color: white !important
    }

    .p-dropdown {
      background: transparent !important;
      border: none !important;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
    }
  }
}

app-create-event {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    // border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }

  .p-dropdown {
    background: transparent !important;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 10px !important;
  }

  .p-calendar {
    .p-inputtext {
      font-size: 18px;
      background: transparent !important;
      border-radius: 10px !important;
      border: 1px solid #707070 !important;
      // border-width: 0px 0px 2px 0px !important;
      color: white;
    }
  }

  .p-datepicker .p-datepicker-header {
    padding: 0px
  }

  .p-datepicker table td>span {
    width: auto !important;
    height: auto !important;
  }

  .p-dialog .p-dialog-content {
    padding: 0 4.5rem 2rem 4.5rem !important;
  }

  .p-radiobutton .p-radiobutton-box {
    background: transparent !important;
  }
}

app-post {
  // input.p-inputtext {
  //   font-size: 16px;
  //   background: transparent !important;
  //   border-radius: 10px !important;
  //   border: 1px solid #707070 !important;
  //   color: white;
  //   padding-left: 10px !important;
  // }

  emoji-mart {
    position: absolute;
    right: 2%;
    z-index: 1;
  }
}

app-comment-post-discussion {
  emoji-mart {
    position: absolute;
    right: 2%;
    z-index: 9;
  }
}

app-edit-description-club {
  .p-dialog .p-dialog-header {
    background: linear-gradient(#000344 0%, #000344 100%);
  }

  .p-dialog .p-dialog-content {
    background: linear-gradient(#000344 0%, #010216 100%);
  }

  textarea {
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    color: #707070;
    padding: 1rem;
  }
}

app-edit-event {
  .p-radiobutton .p-radiobutton-box {
    background: transparent !important;
  }

  .p-calendar {
    .p-inputtext {
      font-size: 18px;
      background: transparent !important;
      border-radius: 10px !important;
      border: 1px solid #707070 !important;
      // border-width: 0px 0px 2px 0px !important;
      color: white;
    }
  }

  .p-datepicker .p-datepicker-header {
    padding: 0px
  }

  .p-datepicker table td>span {
    width: auto !important;
    height: auto !important;
  }

  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    // border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }
}

app-edit-privacy-club {
  .p-dialog .p-dialog-header {
    background: linear-gradient(#000344 0%, #000344 100%);
  }

  .p-dialog .p-dialog-content {
    background: linear-gradient(#000344 0%, #010216 100%);
  }
}

app-list-stage {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 20px !important;
    // border: 1px solid #707070 !important;
    //border-width: 0px 0px 2px 0px !important;
    color: white;
    width: 100%;
  }
}

app-comment-post,
app-comment-album,
app-club-role-moderation {
  .p-dialog .p-dialog-header {
    padding: 0.5rem !important;
  }

  .p-image-preview-container>img {
    height: 120px;
  }

  p-image {
    img {
      border-radius: 5px;
    }
  }

  .p-image-toolbar {
    z-index: 1111;
  }

  emoji-mart {
    position: absolute;
    right: 50% !important;
    z-index: 1;
  }

  .p-galleria .p-galleria-close .p-galleria-close-icon {
    z-index: 1000;
  }
}

.fit-size {
  height: inherit;
  width: inherit;
  object-fit: cover;
  object-position: center;
}

.original-size {
  max-width: 100% !important;
  max-height: 100% !important;
}

app-edit-album,
app-mobile-edit-album {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    // border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }

  .p-dropdown {
    background: transparent !important;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 10px !important;
  }

  .p-radiobutton .p-radiobutton-box {
    background: transparent !important;
  }

  span.p-dropdown-label {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dropdown-panel {
    width: 10rem;
  }

  textarea {
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    color: #707070;
    padding: 1rem;
  }
}

app-go-inlive {
  .p-dialog {
    max-height: 100% !important;
  }

  app-list-viewer-goinlive {
    .p-dialog {
      width: auto !important;
    }
  }

  .p-dialog .p-dialog-header {
    padding: 0px !important;
  }

  .confirm-quit {
    .p-dialog .p-dialog-header {
      background: linear-gradient(#000344 0%, #000344 100%);
    }

    .p-dialog .p-dialog-content {
      background: linear-gradient(#000344 0%, #010216 100%);
    }

    .p-dialog .p-dialog-header-icons {
      padding: 1rem !important;
    }
  }
}

app-go-inlive-viewer,
app-create-album-step-ii {
  .p-dialog {
    max-height: 100% !important;
  }

  .p-dialog .p-dialog-header {
    padding: 0px !important;
  }
}

app-create-post-when-endlive,
app-list-invite-goinlive {
  .p-dialog .p-dialog-header {
    background: linear-gradient(#000344 0%, #000344 100%);
  }

  .p-dialog .p-dialog-content {
    background: linear-gradient(#000344 0%, #010216 100%);
  }

  .p-dialog .p-dialog-header-icons {
    padding: 1rem !important;
  }
}

app-list-viewer-goinlive {
  .p-dialog .p-dialog-header {
    background: linear-gradient(#000344 0%, #000344 100%);
  }

  .p-dialog .p-dialog-content {
    background: linear-gradient(#000344 0%, #010216 100%);
  }

  .p-dialog .p-dialog-header-icons {
    padding: 1rem !important;
  }

  .p-dialog {
    width: 4 0% !important;
  }
}

app-list-event {
  .calendar {
    .p-calendar .p-datepicker {
      width: 1050px;
      background: #0e0f22;
      color: white;
    }

    .p-datepicker .p-datepicker-header {
      background: #0e0f22;
      color: white !important;
      font-weight: bold;
    }

    .p-datepicker table td.p-datepicker-today .p-datepicker table td>span.p-highlight {
      color: white;
      background: #0186fa;
    }

    .p-datepicker table td>span {
      height: 3rem !important;
      border-radius: 30% !important;
    }

    .p-datepicker table th>span {
      color: #959595;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      color: white !important;
      font-size: 20px;
    }
  }
}

app-message {
  .p-checkbox .p-checkbox-box {
    border-radius: 6px !important;
  }

  .pi-check:before {
    width: 10px;
    height: 10px;
    background: white;
  }

  emoji-mart {
    section.emoji-mart {
      position: absolute;
      bottom: 15%;
    }
  }
}

app-schedule-goinlive {
  .p-inputtext {
    font-size: 18px;
    background: transparent !important;
    border-radius: 0px !important;
    // border: 1px solid #707070 !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
  }

  .p-calendar {
    .p-inputtext {
      font-size: 18px;
      background: transparent !important;
      border-radius: 10px !important;
      border: 1px solid #707070 !important;
      // border-width: 0px 0px 2px 0px !important;
      color: white;
    }

    .p-datepicker table td {}

    .p-datepicker table td>span {
      width: 23px;
      height: 23px;
    }
  }
}

app-my-favourite {
  .p-inputtext {
    font-size: 18px;
    border-radius: 12px !important;
    border: none !important;
    border-width: 0px 0px 2px 0px !important;
    color: white;
    background: #8c8c8c5c !important;
  }
}

a {
  color: #50c9ff;
  text-decoration: none;
}

.pac-container {
  background-color: white;
  position: absolute !important;
  z-index: 9999 !important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.pac-logo:after {
  content: '';
  padding: 1px 1px 1px 0;
  height: 16px;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}

.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}

.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: white;
}

.pac-item:hover {
  background-color: #fafafa;
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}

.pac-matched {
  font-weight: 700;
}

.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}

.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}

.pac-icon-search {
  background-position: -1px -1px;
}

.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}

.pac-icon-marker {
  background-position: -1px -161px;
}

.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}

.pac-placeholder {
  color: gray;
}

.pac-logo {
  padding: 10px 0;

  &::after {
    display: none;
  }
}

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"] {
  display: none !important;
}

.gm-bundled-control .gmnoprint {
  display: block;
}

.gmnoprint:not(.gm-bundled-control) {
  display: none;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #109420 !important;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #109420 !important;
}

p-progressBar {
  width: 100%;
}

app-single-page-explorer {
  app-card-goinlive {
    .card {
      width: 91px !important;
      border-radius: 5px !important;
    }

    .icon-club {
      margin-top: -6px !important;
      position: absolute !important;
      margin-left: 55px !important;
      width: 30px !important;
      height: 35px !important;
    }

    .status {
      display: none !important;
    }
  }
}

app-club-notification-setting {
  .p-dialog .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem !important;
  }
}

.skeleton-box {
  display: inline-block;
  height: 100%;
  border-radius: 7px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #909090;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.4) 20%,
        rgba(#fff, 0.8) 60%,
        rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.half-icon-heart {
  width: 1rem;
  height: 1rem;
  background-image: url('./assets/images/svgs/club-type-fan.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.half-icon-star {
  width: 1rem;
  height: 1rem;
  background-image: url('./assets/images/svgs/club-type-talent.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.half-icon-heart-white {
  width: 1rem;
  height: 1rem;
  background-image: url('./assets/images/svgs/half-icon-heart.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.half-icon-star-white {
  width: 1rem;
  height: 1rem;
  background-image: url('./assets/images/svgs/half-icon-star.svg');
  background-repeat: no-repeat;
  background-position: center;
}


ng-waveform {
  div {
    display: flex;
    align-items: center;
  }

  canvas {
    height: 4rem;
    width: 100%;
    padding: 7px 0;
  }
}


app-card-post-celeb-in-club-page,
app-card-post-celeb {
  app-wave-form-audio {
    .zone-audio {
      width: 100%;
      background-color: transparent !important;
      height: 10rem !important;
      overflow: hidden;
      border-radius: 10px;
      border: none !important;
      color: white;
      flex-direction: column;
      align-items: start !important;
    }

    .btn-play {
      display: none;
    }

    .time {
      text-align: left !important;
    }

    ng-waveform canvas {
      height: 5rem !important;
      width: 100%;
    }
  }
}

app-comment-post-discussion {
  app-wave-form-audio {
    .time {
      white-space: nowrap;
    }

    .zone-audio {
      background-color: transparent !important;
      border: none !important;
    }

    canvas {
      background-color: transparent !important;
    }
  }

  // span.p-button-label {
  //   white-space: nowrap;
  //   width: 140px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   color: white;
  // }

  mention-list {
    bottom: 133px;

    .scrollable-menu {
      max-height: 150px !important;
    }
  }

  .content {
    span {
      color: #50c9ff;
    }
  }

  .content {
    a {
      background-color: #64AAEB !important;
      text-decoration: none !important;
      color: white !important;
      border-radius: 5px !important;
      padding: 1px 7px !important;
    }
  }
}

app-report {
  span {
    color: #ffffff !important;
  }
}

app-post-discussion {
  span {
    color: #50c9ff;
  }

  button#black {
    span.p-button-label {
      color: black !important;
    }
  }

  span.p-dialog-header-close-icon {
    color: white;
  }

  span.p-button-label {
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }


  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon,
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    color: white !important;
  }
}

.liked {
  background: -webkit-linear-gradient(#FF5050, #CC3F6A, #852931);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

app-post-detail {
  .post-celeb {
    background-color: transparent !important;
  }

  .text-disabled {
    display: none !important;
  }
}

app-card-post-celeb {
  a {
    color: white;
    text-decoration: underline;
    font-style: italic;
  }
}

app-comment-post {
  mention-list {
    bottom: 150px;

    .scrollable-menu {
      max-height: 150px !important;
    }
  }
}

app-account-blocked {
  a {
    color: white !important;
    font-weight: bold;
  }
}

.pi-pencil-cus {
  background-position: right;
  background-repeat: no-repeat;
  background-image: url('./assets/images/svgs/edit-pencil-white.svg') !important;
}

app-card-event {
  button {
    span.p-button-label {
      font-weight: 500 !important;
    }
  }
}

.position-relative {
  position: relative;
}

.p-galleria-indicator-onitem.p-galleria-indicators-bottom .p-galleria-indicators {
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: flex-end;
  position: fixed !important;
}

app-layout {
  p-toast {
    .p-toast .p-toast-message .p-toast-message-content {
      padding: 0rem !important;
      border-width: 0 0 0 6px;
    }

    .p-toast .p-toast-message.p-toast-message-success {
      background: #ffffff !important;
      border-radius: 10px !important;
      color: #010338 !important;
      border: none !important;

      text-align: left;
      font: normal normal normal 16px/21px DM Sans;
      letter-spacing: 0px;
    }

    .p-toast {
      width: 600px !important;
      max-height: 105px !important;
      min-height: 75px !important;

      .title {
        font-weight: bold;
        white-space: pre-line;
      }

      .subtitle {
        max-height: 65px !important;
        overflow: hidden !important;
        padding-right: 1rem !important;
        overflow-wrap: break-word !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-line;
      }
    }
  }
}

app-create-post-for-admin {
  mention-list {
    top: 2rem;
  }
}

app-view-media-post {
  mention-list {
    top: -15rem;
  }
}

app-upload-avatar {
  .p-dialog .p-dialog-header {
    color: white;
  }
}

ng-waveform {
  // pointer-events: none;
}

app-mobile {
  p-toast {
    .p-toast .p-toast-message .p-toast-message-content {
      padding: 0rem !important;
      border-width: 0 0 0 6px;
    }

    .p-toast .p-toast-message.p-toast-message-info {
      background: #0c81e8 !important;
      border-radius: 10px !important;
      color: #ffffff !important;
      border: none !important;

      text-align: left;
      font: normal normal normal 16px/21px DM Sans;
      letter-spacing: 0px;
    }

    .p-toast {
      width: 85% !important;
      max-height: 105px !important;
      min-height: 75px !important;

      .title {
        font-weight: bold;
        white-space: pre-line;
      }

      .subtitle {
        max-height: 65px !important;
        overflow: hidden !important;
        padding-right: 1rem !important;
        font-size: 12px;
        overflow-wrap: break-word !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-line;
      }

      .ortenir {
        background-color: black;
        border-radius: 5rem;
        padding: 10px;
        color: white;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 2048px) {
  app-landing-page {
    zoom: 0;
    -ms-zoom: 0;
    -webkit-zoom: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .footer-legal {
    zoom: 0.8;
    -ms-zoom: 0.8;
    -webkit-zoom: 0.8;
  }
}

app-landing-page {
  .p-sidebar .p-sidebar-content {
    padding: 0 !important;
  }

  .p-sidebar .p-sidebar-header {
    padding: 0 !important;
  }

  .p-sidebar-full {
    background-color: black !important;
  }
}