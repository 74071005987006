@font-face {
  font-family: "DMSans-regular";
  src: url("./DMSans-Medium.ttf");
}

@font-face {
  font-family: "DMSans-regular";
  src: url("./DMSans-Regular.ttf");
}

@font-face {
  font-weight: bold;
  src: url("./DMSans-bold.ttf");
}

// @font-face {
//   font-family: "poppins-bolditalic";
//   src: url("./Poppins-BoldItalic.ttf");
// }

// @font-face {
//   font-family: "poppins-extrabold";
//   src: url("./Poppins-ExtraBold.ttf");
// }

// @font-face {
//   font-family: "poppins-extrabolditalic";
//   src: url("./Poppins-ExtraBoldItalic.ttf");
// }

// @font-face {
//   font-family: "poppins-extralight";
//   src: url("./Poppins-ExtraLight.ttf");
// }

// @font-face {
//   font-family: "poppins-extralightitalic";
//   src: url("./Poppins-ExtraLightItalic.ttf");
// }

// @font-face {
//   font-family: "poppins-italic";
//   src: url("./Poppins-Italic.ttf");
// }

// @font-face {
//   font-family: "poppins-light";
//   src: url("./Poppins-Light.ttf");
// }

// @font-face {
//   font-family: "poppins-lightitalic";
//   src: url("./Poppins-LightItalic.ttf");
// }

// @font-face {
//   font-family: "poppins-medium";
//   src: url("./Poppins-Medium.ttf");
// }

// @font-face {
//   font-family: "poppins-mediumitalic";
//   src: url("./Poppins-MediumItalic.ttf");
// }

// @font-face {
//   font-family: "poppins-regular";
//   src: url("./Poppins-Regular.ttf");
// }

// @font-face {
//   font-family: "poppins-semibold";
//   src: url("./Poppins-SemiBold.ttf");
// }

// @font-face {
//   font-family: "poppins-thin";
//   src: url("./Poppins-Thin.ttf");
// }
